import React from 'react';
import { Flex, Image, Box, Text } from '@chakra-ui/core';

import ContentContainer from '../ContentContainer';
import ContentResource from './ContentResource';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { H2 } from '@typography';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';
import { contentResources } from '../../lib/consts';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';

const ContentResources = () => {
  return (
    <div
      style={{
        height: '100%',
      }}
      className="content-resources"
    >
      <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
        <Flex direction="column" my={8}>
          <DesktopOnly>
            <Flex direction="row">
              <Box pt={'90px'}>
                <H2>
                  Resources to help get
                  <br />
                  at those money goals
                </H2>
              </Box>
              <Image
                mt={[0, 8]}
                ml={[0, -5]}
                height="130px"
                src={`${cloudfrontBaseUrl}/homepage/themoneydatev2.png`}
              />
            </Flex>
          </DesktopOnly>

          <MobileOnly>
            <Flex direction="column">
              <Box width="35%" height="130px">
                <Image src={`${cloudfrontBaseUrl}/homepage/themoneydatev2.png`} />
              </Box>
            </Flex>

            <H2 pt="45px" textAlign="left" pl={4}>
              Resources to help get
              <br />
              at those money goals
            </H2>
          </MobileOnly>

          <Flex mb={[0, '64px']} pt={[18, '48px']} direction="column">
            <Flex direction={'column'}>
              <DesktopOnly>
                <ContentResource
                  center
                  key="cr-1"
                  variant="ultraMega"
                  imageSource={`${cloudfrontBaseUrl}/homepage/red-box.png`}
                  title="Zeta's guide to combining finances"
                  description="Everything you need to know about merging your finances as a team."
                  ctaUrl="/magazine/combining-finances/"
                />
              </DesktopOnly>

              <MobileOnly>
                <ContentResource
                  key="cr-2"
                  variant="ultraMega"
                  imageSource={`${cloudfrontBaseUrl}/homepage/red-box.png`}
                  title={`Zeta's guide to \n combining finances`}
                  description="Everything you need to know about merging your finances as a team."
                  ctaUrl="/magazine/combining-finances/"
                />
              </MobileOnly>
            </Flex>

            <Flex
              pt={[4, '75px']}
              direction={['column', 'row']}
              width={['100%', '100%']}
              justify="space-between"
            >
              {contentResources.map(({ title, description, ctaUrl, imageSource }) => (
                <ContentResource
                  key={`cr-${title}`}
                  title={title}
                  description={description}
                  ctaUrl={ctaUrl}
                  imageSource={imageSource}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </div>
  );
};

export default ContentResources;
