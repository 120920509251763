import React, { useState, useEffect } from 'react';
import { Flex, Image } from '@chakra-ui/core';
import AnimatedText from 'react-animated-text-content';

import { H2 } from '@typography';
import { colors, animations } from '@theme';
import { cloudfrontBaseUrl } from '../../lib/consts';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';

const ZetaIsFor = () => {
  const dSet = [
    {
      idx: 0,
      src: `${cloudfrontBaseUrl}/homepage/zetaisfor/partnersv2.png`,
      mobileSrc: `${cloudfrontBaseUrl}/homepage/zetaisfor/partners-mobile.png`,
      word: 'partners',
    },
    {
      idx: 1,
      src: `${cloudfrontBaseUrl}/homepage/zetaisfor/siblings.png`,
      mobileSrc: `${cloudfrontBaseUrl}/homepage/zetaisfor/siblings-mobile.png`,
      word: 'siblings',
    },
    {
      idx: 2,
      src: `${cloudfrontBaseUrl}/homepage/zetaisfor/grand.png`,
      mobileSrc: `${cloudfrontBaseUrl}/homepage/zetaisfor/grand-mobile.png`,
      word: 'grandparents',
    },
    {
      idx: 3,
      src: `${cloudfrontBaseUrl}/homepage/zetaisfor/couple.png`,
      mobileSrc: `${cloudfrontBaseUrl}/homepage/zetaisfor/couple-mobile.png`,
      word: 'couples',
    },
    {
      idx: 4,
      src: `${cloudfrontBaseUrl}/homepage/zetaisfor/care.png`,
      mobileSrc: `${cloudfrontBaseUrl}/homepage/zetaisfor/care-mobile.png`,
      word: 'caretakers',
    },
    {
      idx: 5,
      src: `${cloudfrontBaseUrl}/homepage/zetaisfor/families.png`,
      mobileSrc: `${cloudfrontBaseUrl}/homepage/zetaisfor/families-mobile.png`,
      word: 'parents & kids',
    },
  ];
  const [n, setN] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (n === dSet.length - 1) {
        setN(0);
      } else {
        setN(n => n + 1);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [n]);

  const active = dSet[n];

  if (!active?.src) return null;

  return (
    <div className="zeta-is-for">
      <MobileOnly>
        <div>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              zIndex: 1,
              transform: 'translate(-50%, -50%)',
              marginTop: '290px',
              width: '100%',
            }}
          >
            <H2 fontSize="48px" color={colors.white} style={{ width: '100%' }}>
              <span style={{ fontWeight: 400 }}>Zeta is for</span>
              <br />

              <AnimatedText
                type="words"
                interval={0.04}
                duration={0.8}
                animation={{
                  y: '100px',
                  ease: 'ease',
                }}
              >
                {active.word}
              </AnimatedText>
            </H2>
          </div>
          {dSet.map(graphic => (
            <Flex
              key={`zeta-is-for-${graphic.idx}`}
              style={{ display: n === graphic.idx ? undefined : 'none' }}
            >
              <Image
                style={animations.fade}
                loading="eager"
                width="100%"
                src={graphic.mobileSrc}
              />
            </Flex>
          ))}
        </div>
      </MobileOnly>

      <DesktopOnly>
        <div>
          {dSet.map(graphic => (
            <Flex
              key={`zeta-is-for-${graphic.idx}`}
              style={{ display: n === graphic.idx ? undefined : 'none' }}
            >
              <Image
                style={animations.fade}
                loading="eager"
                width="100%"
                src={graphic.src}
              />
            </Flex>
          ))}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
            }}
          >
            <H2 fontSize="84px" color="#ffffff" style={{ width: '100%' }}>
              <span style={{ fontWeight: 400 }}>Zeta is for</span>
              <br />
              <AnimatedText
                type="words"
                interval={0.04}
                duration={0.8}
                animation={{
                  y: '100px',
                  ease: 'ease',
                }}
              >
                {active.word}
              </AnimatedText>
            </H2>
          </div>
        </div>
      </DesktopOnly>
    </div>
  );
};

export default ZetaIsFor;
