import React from 'react';
import { Flex } from '@chakra-ui/core';
import Layout from '../components/layout';
import {
  TopSection,
  ContentResources,
  ZetaIsFor,
  JointAccounts,
  CustomizeZeta,
  Metadata,
} from '@home';

const Index = () => {
  return (
    <>
      <Layout
        transparent
        noRootContainer
        showGetStartedMobile={false}
        showGetStartedDesktop={true}
        mode={'light'}
        newNav
      >
        <Metadata />
        <Flex
          className="heading-section"
          width="100%"
          justifyContent="center"
          direction="column"
        >
          <TopSection />
        </Flex>

        <JointAccounts />
        <CustomizeZeta />
        <ZetaIsFor />
        <ContentResources />
      </Layout>
    </>
  );
};

export default Index;
