import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/core';
import Lottie from 'react-lottie';

import { AppStoreBadge, Button } from '@zeta';
import { H1 } from '@typography';
import { APP_CONTENT_WIDE_WIDTH } from '../../styles/sizes';
import ContentContainer from '../../components/ContentContainer';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import AsSeenIn from '../../components/home/asSeenIn';
import GradientBackground from '../../assets/HomeBgDesktop.png';
import GradientBackgroundMobile from '../../assets/HomeBgMobile.png';
import animation from '../../lotties/phone-animation.json';

const TopSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };

  const Content = () => {
    return (
      <>
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Flex
            ml={[0, '100px']}
            direction="column"
            mb={['32px', '62px']}
            pt={['5rem', 0]}
          >
            <Flex direction={['column', 'row']} className="topsection-main-container">
              <Flex pt={['24px', '225px']} direction="column">
                <Flex justify={['center', 'left']}>
                  <Box
                    width="250px"
                    height="35px"
                    px="12px"
                    pt={'5px'}
                    sx={{
                      border: `1px solid #000000`,
                      borderRadius: '40px',
                    }}
                  >
                    <Text justifyContent="center" align="center" size="16px">
                      MASTER MONEY AS A TEAM
                    </Text>
                  </Box>
                </Flex>
                <Box mt={['12px', '24px']}>
                  <H1>
                    Modern-day
                    <br />
                    banking to help
                    <br />
                    your family thrive
                  </H1>
                </Box>

                <Flex
                  direction={['column', 'row']}
                  mt={['32px', '48px']}
                  justifyContent={['center', 'left']}
                  mb={[0, 2]}
                >
                  <Flex justify={['center', 'left']}>
                    <Button
                      style={{
                        height: '40px',
                        width: '121px',
                      }}
                      text="Get started"
                      variant="greenPea"
                      onClick={() => {
                        window.location.href = 'https://askzeta.app.link/getstarted';
                      }}
                    />
                  </Flex>

                  <MobileOnly>
                    <div style={{ cursor: 'none', minHeight: '380px' }}>
                      <Lottie
                        style={{
                          cursor: 'auto',
                        }}
                        isClickToPauseDisabled={true}
                        options={defaultOptions}
                        width={'auto'}
                      />
                    </div>
                  </MobileOnly>

                  <Flex pt={[0, '5px']} justify={['center', 'left']} direction="row">
                    <Flex pl={[0, '24px']}>
                      <DesktopOnly>
                        <AppStoreBadge height={40} platform="android" zjcDirect />
                      </DesktopOnly>
                      <MobileOnly>
                        <AppStoreBadge height={30} platform="android" zjcDirect />
                      </MobileOnly>
                    </Flex>
                    <Flex pl={['24px', '24px']}>
                      <DesktopOnly>
                        <AppStoreBadge height={40} platform="ios" zjcDirect />
                      </DesktopOnly>
                      <MobileOnly>
                        <AppStoreBadge height={30} platform="ios" zjcDirect />
                      </MobileOnly>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <DesktopOnly>
                <div style={{ paddingTop: '100px', paddingLeft: '62px' }}>
                  <Lottie
                    style={{
                      cursor: 'auto',
                    }}
                    isClickToPauseDisabled={true}
                    options={defaultOptions}
                    height={600}
                    width={500}
                  />
                </div>
              </DesktopOnly>
            </Flex>
          </Flex>
        </ContentContainer>
        <AsSeenIn />
      </>
    );
  };

  return (
    <>
      <DesktopOnly>
        <div
          style={{
            backgroundImage: `url(${GradientBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Content />
        </div>
      </DesktopOnly>
      <MobileOnly>
        <div
          style={{
            backgroundImage: `url(${GradientBackgroundMobile})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Content />
        </div>
      </MobileOnly>
    </>
  );
};

export default TopSection;
