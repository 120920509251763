import React, { useState, useEffect } from 'react';
import { Flex, Image, Box } from '@chakra-ui/core';

import ContentContainer from '../ContentContainer';
import { colors, animations } from '@theme';
import { APP_CONTENT_WIDE_WIDTH } from '../../styles/sizes';
import { H2, H3, Text } from '@typography';
import { LearnMoreButton } from '@zeta';
import { families } from '../../lib/consts';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import DesktopFrameBg from '../../assets/DesktopFrameBg.png';
import JaMedallion from '../../assets/JaMedallion.png';

const JointAccounts = () => {
  const [selectedFamily, setSelectedFamily] = useState(families[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedFamily.index !== 4)
        setSelectedFamily(families[selectedFamily.index + 1]);
      else setSelectedFamily(families[0]);
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedFamily.index]);

  return (
    <div className="joint-accounts" style={{ backgroundColor: colors.background }}>
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
        <Flex height={['auto', 'auto']} pt="64px" justify="center">
          <MobileOnly>
            <H2>
              Zeta Joint Accounts
              <br />
              are designed for
              <br />
              families
            </H2>

            <Flex
              pb="48px"
              alignItems="center"
              justify="center"
              pt="24px"
              direction="column"
            >
              <div style={{ position: 'relative' }}>
                <Image loading="eager" src={DesktopFrameBg} width={300} />

                <Image
                  loading="eager"
                  src={JaMedallion}
                  style={{
                    position: 'absolute',
                    top: -30,
                    right: 0,
                    zIndex: 1,
                  }}
                  width={140}
                />
                {families.map(
                  (_, idx) =>
                    selectedFamily.index === idx && (
                      <Image
                        loading="eager"
                        key={idx}
                        style={{
                          position: 'absolute',
                          left: 62,
                          top: 34,
                          borderRadius: 26,
                        }}
                        width={176}
                        sx={animations.fade}
                        src={selectedFamily.image}
                      />
                    ),
                )}
              </div>

              <Flex justifyContent="space-evenly" width="180px" pt="32px" direction="row">
                {families.map((_, idx) => (
                  <Box
                    key={idx}
                    mx="8px"
                    height={14}
                    width={14}
                    as="button"
                    style={
                      selectedFamily.index === idx
                        ? styles.mobileSelectedItem
                        : styles.mobileUnselectedItem
                    }
                    onClick={() => {
                      setSelectedFamily(families[idx]);
                    }}
                  />
                ))}
              </Flex>

              <H3
                style={{
                  paddingTop: '28px',
                }}
              >
                {selectedFamily.title}
              </H3>
              <Text
                style={{
                  paddingTop: '12px',
                  width: '350px',
                  lineHeight: '32px',
                  textAlign: 'center',
                }}
              >
                {selectedFamily.description}
              </Text>

              <div
                style={{
                  paddingTop: '28px',
                }}
              >
                <LearnMoreButton />
              </div>
            </Flex>
          </MobileOnly>
        </Flex>

        <DesktopOnly>
          <H2>
            Zeta Joint Accounts are
            <br />
            designed for families
          </H2>

          <Flex mt={[0, '100px']} direction={['column', 'row']}>
            <Flex width={['100%', '40%']} ml={[0, '48px']} direction="column">
              <div
                style={{
                  position: 'relative',
                  maxHeight: '500px',
                  overflow: 'hidden',
                  paddingLeft: '5rem',
                }}
              >
                <Image loading="eager" src={DesktopFrameBg} width={420} />

                <Image
                  src={JaMedallion}
                  style={{
                    position: 'absolute',
                    top: -30,
                    right: 0,
                    zIndex: 1,
                  }}
                  width={170}
                />
                {families.map(
                  (_, idx) =>
                    selectedFamily.index === idx && (
                      <Image
                        key={idx}
                        style={{
                          position: 'absolute',
                          left: 163,
                          top: 45,
                          borderRadius: 36,
                        }}
                        width={234}
                        sx={animations.fade}
                        src={selectedFamily.image}
                      />
                    ),
                )}
              </div>
            </Flex>
            <Flex pl="80px" justifyContent="center" direction="column" pt={4}>
              <H3>{selectedFamily.title}</H3>
              <Text
                style={{
                  paddingTop: '1rem',
                  width: '420px',
                  lineHeight: '1.7rem',
                }}
              >
                {selectedFamily.description}
              </Text>

              <div
                style={{
                  paddingTop: '28px',
                }}
              >
                <LearnMoreButton />
              </div>

              <Flex
                justifyContent="space-between"
                width="260px"
                pt="68px"
                direction="row"
              >
                {families.map((_, idx) => (
                  <Box
                    key={idx}
                    height={22}
                    width={22}
                    as="button"
                    style={
                      selectedFamily.index === idx
                        ? styles.selectedItem
                        : styles.unselectedItem
                    }
                    onClick={() => {
                      setSelectedFamily(families[idx]);
                    }}
                  />
                ))}
              </Flex>
            </Flex>
          </Flex>
        </DesktopOnly>
      </ContentContainer>
    </div>
  );
};

const styles = {
  selectedItem: {
    width: '50px',
    height: '22px',
    borderRadius: '66px',
    background: colors.black,
  },
  unselectedItem: {
    borderRadius: '35px',
    border: `1px solid black`,
  },

  mobileSelectedItem: {
    width: '28px',
    height: '14px',
    borderRadius: '66px',
    background: colors.black,
  },
  mobileUnselectedItem: {
    borderRadius: '35px',
    border: `1px solid black`,
  },
};

export default JointAccounts;
