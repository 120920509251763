import React from 'react';
import { Flex, Box } from '@chakra-ui/core';
import Lottie from 'react-lottie';

import { colors } from '@theme';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';
import ContentContainer from '../ContentContainer';
import { H3, Text } from '@typography';
import { LearnMoreButton } from '@zeta';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import animation from '../../lotties/circle-animation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
};

const CustomizeZeta = () => {
  return (
    <div className="customize-zeta" style={{ backgroundColor: colors.white }}>
      <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
        <MobileOnly>
          <Flex
            justify="center"
            height={['650px', '600px']}
            align="center"
            direction="column"
          >
            <Flex align="center" ml={[0, '96px']} direction="column">
              <H3>
                Customize Zeta to
                <br />
                your family
              </H3>

              <Box>
                <Lottie
                  style={{
                    cursor: 'auto',
                  }}
                  isClickToPauseDisabled={true}
                  options={defaultOptions}
                  height={300}
                  width={300}
                />
              </Box>

              <Text
                style={{
                  lineHeight: '32px',
                  textAlign: 'center',
                  width: '300px',
                }}
              >
                Whether you combine your finances
                <br />
                or keep them apart — when your team
                <br />
                grows, Zeta grows with you.
              </Text>

              <div style={{ marginTop: '32px' }}>
                <LearnMoreButton />
              </div>
            </Flex>
          </Flex>
        </MobileOnly>

        <DesktopOnly>
          <Flex
            justify="space-between"
            height={['500px', '600px']}
            align="center"
            direction="row"
            mt={4}
          >
            <Flex direction="column">
              <H3>
                Customize Zeta to the
                <br />
                shape of your family
              </H3>

              <Text style={{ lineHeight: '32px', paddingTop: '24px' }}>
                Whether you combine your finances or
                <br />
                keep them apart — when your team grows,
                <br />
                Zeta grows with you.
              </Text>

              <div style={{ paddingTop: '24px' }}>
                <LearnMoreButton />
              </div>
            </Flex>

            <Lottie
              style={{
                cursor: 'auto',
              }}
              isClickToPauseDisabled={true}
              options={defaultOptions}
              width={600}
            />
          </Flex>
        </DesktopOnly>
      </ContentContainer>
    </div>
  );
};

export default CustomizeZeta;
