import React from 'react';
import Helmet from 'react-helmet';
import { Flex } from '@chakra-ui/core';

const Metadata = () => {
  const helmet = React.useMemo(
    () => (
      <Helmet>
        <title>Zeta: Transforming finances for couples and families</title>
        <meta
          name="description"
          content="Zeta is revolutionizing personal finance for couples and families. We offer modern-day joint and personal bank accounts designed to help families thrive."
        />
        <meta
          property="og:description"
          content="Zeta is revolutionizing personal finance for couples and families. We offer modern-day joint and personal bank accounts designed to help families thrive."
        />
        <meta
          name="keywords"
          content="joint bank account, joint banking, joint account, best joint bank account"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="alternate" href="https://www.askzeta.com" hreflang="en" />
        <link rel="canonical" href="https://www.askzeta.com" />
        <meta
          name="keywords"
          content="personal finance, banking, joint banking, joint account, couples finance, family finance, love and money, combining finances, sharing finances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:title"
          content="Zeta: Transforming finances for couples and families"
        />
        <meta
          property="og:site_name"
          content="Zeta: Transforming finances for couples and families"
        />
        <meta property="og:url" content="https://www.askzeta.com" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
      </Helmet>
    ),
    [],
  );
  return <Flex>{helmet}</Flex>;
};

export default Metadata;
